.App {
    /* text-align: center; */
}

.App-logo {
    /* pointer-events: none; */
    /* width: 120px; */
    height: 80px;
    padding: 30px;
}


/* .App-header {
  background-color: #282c34;
  background-color: #5778F7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinning {
    animation: App-logo-spin infinite 2s linear;
    margin: 0 5px;
}

body {
    font-family: 'Josefin Sans', sans-serif;
    /* background-color: #F2F2F2; */
    /* background: #4A6FF9; */
    background: url(/images/BG_Shapes.png), linear-gradient(180deg, #4364F7 0%, #6FB1FC 100%);
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    color: #fff;
}

img {
    max-width: 100%;
}

h1,
h2 {
    /* font-family: 'Playfair Display', serif; */
    /* font-weight: 900; */
    position: relative;
    display: inline-block;
}

h1 {
    text-align: center;
    font-size: 35px;
    margin-bottom: 25px;
    /* z-index: 10; */
    /* margin: 0 auto; */
}

h1:after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 6px);
    height: 2px;
    background-color: #FFF;
    transform: translate(-3px, 0px);
    /* transform: skewX(-45deg) translate(-7px, -7px); */
    z-index: -1;
}

h2 {
    font-size: 20px;
    /* margin-bottom: 50px; */
}

h2.lines {
    /* font-size: 35px; */
    display: block;
    margin-bottom: 25px;
}

h2.lines:after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 6px);
    height: 2px;
    background-color: #4A73C1;
    transform: translate(-3px, 0px);
    /* transform: skewX(-45deg) translate(-7px, -7px); */
    z-index: -1;
}

p {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 22px;
    margin-bottom: 2em;
}

a {
    color: #FFF;
    font-weight: 500;
}

.navbar.bg-dark {
    background-color: #5778F7!important;
    margin-bottom: 50px;
}

.btn {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    margin-bottom: 2em;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary.focus,
.btn-primary:focus {
    background-color: #FFF;
    border-color: #FFF;
    color: #4a6ff9;
}

.btn-primary:hover {
    background-color: #4A73C1;
    border-color: #4A73C1;
    color: #FFF;
}

.btn-link {
    color: #FFF;
    border: 2px solid #FFF;
    border-radius: 10px;
    padding: .3rem .75rem;
}

.btn-link:hover {
    color: #FFF;
}

.btn.spotify {
    border-color: #1DB954;
    background-color: #1DB954;
    color: #FFF;
    margin-top: 30px;
}

.btn.spotify svg {
    margin: 0 10px 3px 0;
}

.btn.youtube {
    border-color: #FF0000;
    background-color: #FF0000;
    color: #FFF;
    margin-top: 30px;
}

.btn.youtube svg {
    margin: 0 10px 3px 0;
}

.btn.musingo {
    border-color: #0057FF;
    background-color: #0057FF;
    color: #FFF;
    margin-top: 30px;
}

.btn.musingo svg {
    margin: 0 10px 3px 0;
}

.btn.back {
    position: absolute;
    right: 20px;
    top: 65px;
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.form-wrap {
    text-align: center;
    padding-top: 80px;
}

.form-group .btn-link {
    margin: 0;
}

.input-group-text {
    background-color: #fff;
}

.form-control {
    border-radius: 8px;
    box-shadow: inset #467fb7 0px 0px 2px;
}

.form-control.h1 {
    display: inline;
    text-align: center;
    font-size: 35px;
    margin-bottom: 25px;
    background: none;
    border: none;
    color: #FFF;
    box-shadow: none;
    border-bottom: 2px solid #FFF;
    border-radius: 0;
    width: 210px;
    padding: 0 3px;
}

.form-control.h1:after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 6px);
    height: 2px;
    background-color: #FFF;
    transform: translate(-3px, 0px);
    z-index: -1;
}

.form-control.h1::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFF;
    opacity: 0.5;
}

.custom-control-label {
    margin-right: 20px;
    margin-bottom: 1rem;
}

.login .btn {
    margin-bottom: 1rem;
}

.login p {
    color: #9B9B9B;
}

.header-logo {
    width: 50px;
}

.alert-info {
    color: #9733EE;
    background-color: #eadaf9;
    border-color: #eadaf9;
}

.alert p {
    margin-bottom: 0;
}

.alert .btn {
    margin: 0 20px;
}

#spotify-player,
#yt-player {
    margin-bottom: 2rem;
}

#spotify-connect {
    max-height: 500px;
}

.prev-tracks {
    justify-content: flex-end;
}

.next-tracks,
.prev-tracks {
    display: flex;
    align-items: center;
}

.track.next:last-of-type,
.track.prev:first-of-type {
    flex: 0 0 40%;
    font-size: 0.5rem;
}

.track.next:first-of-type,
.track.prev:only-of-type,
.track.prev:last-of-type {
    flex: 0 0 60%;
    font-size: 0.8rem;
}

.track {
    padding: 0 10px;
}

.track span {
    max-width: 100%;
}

#yt-player .current iframe {
    width: 100%;
}

@media(max-width:768px) {
    #yt-player .track.next:last-of-type,
    #yt-player .track.prev:first-of-type {
        display: none;
    }
    #yt-player .track.next:first-of-type,
    #yt-player .track.prev:only-of-type,
    #yt-player .track.prev:last-of-type {
        flex: initial;
        display: initial;
    }
}

#invite {
    /* margin-top: -50px; */
    /* min-height: 100vh; */
    /* display: flex; */
    /* justify-content: stretch; */
}

.vads-invite {
    margin-left: -15px;
    width: calc(100% + 30px);
    /* max-width: unset; */
    min-height: 40vh;
    /* min-height: calc(100vh - 50px); */
    background-position: center;
    background-size: cover;
}

#invite-wrap {
    width: 100%;
    padding: 3rem 30px;
}

@media(min-width:575px) {
    footer {
        display: flex;
        justify-content: space-between;
    }
}

@media(min-width:768px) {
    #invite-wrap {
        padding: 3rem 80px;
    }
    .vads-invite {
        min-height: 100vh;
    }
}

#bingo {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    margin-top: -50px;
    padding: 50px 0;
}

#bingo.un-auth {
    margin-top: 0;
}

#bingo.un-auth.dark-theme {
    color: #323232;
}

#bingo.un-auth.dark-theme .btn-link {
    color: #323232;
    border-color: #323232;
}

#invite.un-auth {
    margin-top: 0;
}

#invite.un-auth.dark-theme {
    color: #323232;
}

#invite.un-auth.dark-theme .btn-link {
    color: #323232;
    border-color: #323232;
}

#invite.un-auth.dark-theme .form-control {
    color: #323232;
    border-color: #323232;
}

#invite.un-auth.dark-theme .form-control.h1::placeholder {
    color: #323232;
    border-color: #323232;
}

.modal .light-theme {
    color: #fff;
    overflow: hidden;
}

.modal #bingo .col-md-6 {
    flex: 0 0 80%;
    max-width: 80%;
}

.modal #bingo .btn {
    margin-bottom: 0.5rem;
}

.modal #bingo .btn img {
    background-color: #fff;
    padding: 20px 3px;
}

#bingo .btn span {
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
}

#bingo .btn.card {
    border-radius: 0;
    padding: 2px;
    margin-bottom: 8px;
    font-size: 13px;
    background-color: #FFF;
    border-color: #FFF;
    color: #4A73C1;
    box-shadow: #4b73d0 0px 3px 2px 0px;
    display: flex;
    width: 100%;
    text-align: center;
}

#bingo .btn.card svg {
    margin-bottom: 5px;
}

#bingo .btn img {
    border-radius: 0;
    margin-bottom: 5px;
}

.ytImage-container {
    width: 100%;
    height: 100%;
    line-height: 0;
}

.ytImage-bg {
    display: inline-block;
    width: 50%;
    padding-bottom: 50%;
    background-position: center;
    background-size: cover;
}

.ytImage-bg-one {
    display: inline-block;
    width: 100%;
    padding-bottom: 100%;
    background-position: center;
    background-size: cover;
}

.ytImage-bg-lg {
    display: inline-block;
    width: 100%;
    padding-bottom: 100%;
    background-position: center;
    background-size: cover;
}

#bingo .btn .ytImage img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0;
}

#bingo .btn .stamp {
    position: absolute;
    width: 80%;
    left: 50%;
    top: 38%;
    transform: translate(-50%, -50%);
    top: 5px;
    transform: translate(-50%, 0);
}

#bingo .btn .tick {
    position: absolute;
    right: 30px;
    top: 15px;
    color: #fff;
    /* color: firebrick; */
    /* box-shadow:#4b73d0 0px 3px 2px 0px; */
    filter: drop-shadow(0px 3px 2px #4b73d0);
    font-size: 30px;
}

#bingo .btn .crop {
    position: absolute;
    width: calc(100% - 34px);
    overflow: hidden;
    left: 17px;
    padding-bottom: 100%;
}

#bingo .btn .tick.custom {
    top: unset;
    right: unset;
    width: calc( 100% - (1.05rem * 2));
    padding-bottom: calc( 100% - (1.05rem * 2));
    background-position: center;
    background-size: cover;
}

#bingo .btn .plus {
    font-size: 30px;
    display: block;
    margin: 50% auto;
    flex-grow: 1;
}

@media all and (min-width: 768px) {
    #bingo .btn .plus {
        margin: 30px auto;
    }
}

.card-wrap {
    margin-bottom: 1rem;
}

.card-wrap.row {
    margin-left: -4px;
    margin-right: -4px;
}

.card-wrap.row>div {
    padding-left: 4px;
    padding-right: 4px;
}

.card-wrap.row>div>div {
    width: 100%;
}

.char-count {
    color: var(--gray);
    text-align: right;
    font-size: 1.2em;
}

#zoom {
    background-image: url(/images/ydbb_elements-01.png);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
}

#zoom #bingo {
    background: none;
}

#zoom #bingo .col-md-6 {
    flex: 0 0 80%;
    max-width: 80%;
}

.text-page h2,
.text-page h3,
.text-page h4,
.text-page p,
#help h2,
#help h3,
#help h4,
#help p,
#about h2,
#about h3,
#about h4,
#about p {
    text-align: left;
}

#help iframe,
#about iframe {
    margin-bottom: 2rem;
}

footer {
    margin-bottom: 1rem;
}

footer .nav-link svg {
    font-size: 30px;
}

.modal {
    color: #4A73C1;
}

.modal-content {
    background-color: #f6f6f4;
    overflow: hidden;
}

.modal-body {
    /* padding: 1rem 0 0 3rem; */
}

.modal p {
    margin-bottom: 40px;
    text-align: left;
}

.modal .btn {
    margin-bottom: 0;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.modal .btn-secondary {
    margin-bottom: 2rem;
}

.char-count {
    color: #fff;
    font-size: 0.8rem;
}

.card {
    border-radius: 0;
    padding: 20px;
    margin-bottom: 8px;
    /* font-size: 13px; */
    /* background-color: #FFF; */
    border-color: #FFF;
    color: #4A73C1;
    box-shadow: #4b73d0 0px 3px 2px 0px;
    display: flex;
    /* width: 100%; */
    text-align: left;
    overflow: hidden;
}

.card p {
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 1rem;
    ;
}

.card .btn-link {
    margin-bottom: 0;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.card .btn-primary {
    margin-bottom: 0;
    border-color: #007bff;
}

.card p:last-of-type {
    margin-bottom: 0;
}

.table-bordered td,
.table-bordered th,
.table thead th {
    /* border-color: #; */
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #007bff0d;
}

@media all and (min-width: 480px) {
    .ResetPassword {
        padding: 60px 0;
    }
    .ResetPassword form {
        margin: 0 auto;
        max-width: 320px;
    }
    .ResetPassword .success {
        max-width: 400px;
    }
}

.ResetPassword .success {
    margin: 0 auto;
    text-align: center;
}

.ResetPassword .success .glyphicon {
    color: grey;
    font-size: 30px;
    margin-bottom: 30px;
}


/* input, */

.StripeElement {
    display: block;
    margin: 10px auto 20px auto;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    /* font-family: "Source Code Pro", monospace; */
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

input::placeholder {
    color: #aab7c4;
}

input:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.PaymentRequestButton {
    height: 40px;
}

#particles-js {
    position: absolute;
    /* border: 1px solid; */
    top: 100px;
    /* right: 0; */
    bottom: 0;
    left: 0;
    right: calc(75% - 30px);
    /* background: linear-gradient(to right, white, transparent 90%); */
    /* filter: blur(20px); */
}

.ytImage {
    margin: 0px;
    padding: 0px;
    margin-bottom: 0px;
}

.noscroll {
    overflow: hidden;
}